






import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import adsAgencyTypes from "@/constants/adsAgencyTypes";
import userManager from "@/services/userManager";
import makeSelectOptions from "@common/util/makeSelectOptions";
import DataContainer from "@common/modules/vuetifyx/common/DataContainer";
import ItemHistory from "@/components/ItemHistory.vue";

function tariffItemsInput() {
  return {
    type: "XArrayInput",
    attrs: {
      label: "Bảng phí",
      xOptions: {
        content: {
          itemLabel: (item) =>
            `${Vue.filter("currency")(item.minimumAdsValue) || ""} - ${
              Vue.filter("currency")(item.maximumAdsValue) || ""
            }: ${
              (item.fixCost && Vue.filter("currency")(item.fixCost)) || (item.percentage && item.percentage + "%") || ""
            }`,
          itemProperties: {
            accountType: {
              attrs: {
                label: "Loại TK",
              },
            },
            minimumAdsValue: {
              type: "integer",
              attrs: {
                label: "Min",
              },
            },
            maximumAdsValue: {
              type: "integer",
              attrs: {
                label: "Max",
                required: true,
              },
            },
            fixCost: {
              type: "integer",
              attrs: {
                label: "Giá fix",
              },
            },
            percentage: {
              type: "number",
              attrs: {
                label: "Tỉ lệ %",
              },
            },
          },
          template: {
            formOptions: {
              content: {
                colLength: 2,
              },
            },
          },
        },
      },
    },
  };
}

const displayFields = {
  name: {
    text: "Tên",
    sortable: true,
    options: {
      to(_, item) {
        return `/app/ads/agencies/${item._id}/`;
      },
    },
  },
  code: {
    text: "Mã",
    sortable: true,
  },
  description: {
    text: "Mô tả",
    sortable: true,
  },
  type: {
    text: "Phân loại",
    sortable: true,
    options: {
      label(value) {
        return adsAgencyTypes[value || ""];
      },
    },
  },
  adsCard: {
    text: "Thẻ",
    sortable: true,
    options: {
      label: true,
      subProp: "adsCard.name",
      sortBy: "adsCardId",
    },
  },
  contactPerson: {
    text: "Thông tin liên hệ",
    sortable: true,
  },
  bankAccount: {
    text: "Tài khoản ngân hàng",
    sortable: true,
  },
  feePercentage: {
    text: "% phí",
  },
  createdTime: {
    text: "Ngày tạo",
    sortable: true,
    options: {
      filter: "dateTime",
    },
  },
  lastFeePercentageStartTime: {
    text: "Ngày chuyển % phí",
    sortable: true,
    options: {
      filter: "dateTime",
    },
  },
  lastTariffItemsStartTime: {
    text: "Ngày chuyển bảng phí",
    sortable: true,
    options: {
      filter: "dateTime",
    },
  },
  action: {},
};

export default Vue.extend({
  data() {
    return {
      tableOptions: {
        attrs: {
          "item-key": "_id",
          "sort-by": "name",
          "sort-desc": false,
        },
        content: {
          name: "Quản lý Agency",
          urlEnabled: true,
          search: {},
          filters: {
            filters: {
              type: {
                type: "select",
                attrs: {
                  label: "Phân loại",
                  items: makeSelectOptions(adsAgencyTypes),
                },
                rules: {
                  equal_to: {},
                },
              },
            },
          },
          displayFields,
          topActionButtons: {
            insert: {
              target: {
                dialog: {
                  attrs: {
                    width: "60%",
                  },
                },
              },
            },
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["admin", "kt", "ksnb", "ksnbl"]) && {
                target: {
                  dialog: {
                    attrs: {
                      width: "60%",
                    },
                    ext: {
                      subTitleMaker: "name",
                    },
                  },
                },
              },
              delete: userManager.checkRole(["admin"]) && {},
              presetFeePercentage: userManager.checkRole(["kt", "ksnb", "ksnbl"]) && {
                attrs: {},
                content: {
                  icon: "mdi-currency-usd",
                },
                target: {
                  tooltip: {
                    content: {
                      text: "Cập nhật % phí",
                    },
                  },
                  dialog: {
                    handlers: {
                      initialize() {
                        this.options.content.buttons.save.states.enabled = new DataContainer(false);
                      },
                    },
                    content: {
                      toolbar: {
                        title: "Cập nhật % phí",
                        subTitle(dialog) {
                          const btn = dialog.context();
                          const { item } = btn.context();
                          return item.name;
                        },
                      },
                      content: {
                        type: "XForm",
                        makeAttrs(attrs, dialog) {
                          const { item } = dialog.context().context();
                          attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                          attrs.xContext = dialog;
                          attrs.xData = new DataContainer({
                            nextFeePercentage: item.nextFeePercentage,
                            nextFeePercentageStartTime: item.nextFeePercentageStartTime,
                          });
                          attrs.xOptions = {
                            content: {
                              sections: {
                                default: {
                                  fields: {
                                    nextFeePercentage: {
                                      type: "number",
                                      attrs: {
                                        label: "% phí mới",
                                        autofocus: true,
                                      },
                                    },
                                    nextFeePercentageStartTime: {
                                      type: "XDateTimePicker",
                                      attrs: {
                                        label: "Ngày chuyển % phí",
                                        xOptions: {
                                          content: {
                                            resetable: true,
                                          },
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          };
                          return attrs;
                        },
                        attrs: {},
                      },
                      buttons: {
                        save: {
                          content: {
                            text: "Cập nhật",
                          },
                          states: {},
                          on: {
                            async xClick({ self }) {
                              const dialog = self.context();
                              const btn = dialog.context();
                              const { item, dataTable } = btn.context();
                              const form = dialog.contentContainer.value;
                              const data = form.getData();
                              self.loading.value = true;
                              const result = await dataTable.execute(
                                async () => {
                                  return await coreApiClient.call(
                                    "adsAgencies",
                                    "update",
                                    {
                                      id: item._id,
                                    },
                                    {
                                      data: {
                                        nextFeePercentage: data.nextFeePercentage,
                                        nextFeePercentageStartTime: data.nextFeePercentageStartTime,
                                      },
                                    }
                                  );
                                },
                                undefined,
                                {
                                  disableLoading: true,
                                  willRefresh: true,
                                }
                              );
                              self.loading.value = false;
                              if (result) {
                                dialog.hide();
                              }
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
              presetTariffItems: userManager.checkRole(["kt", "ksnb", "ksnbl"]) && {
                attrs: {},
                content: {
                  icon: "mdi-currency-usd",
                },
                target: {
                  tooltip: {
                    content: {
                      text: "Cập nhật bảng phí",
                    },
                  },
                  dialog: {
                    attrs: {
                      width: "800px",
                    },
                    handlers: {
                      initialize() {
                        this.options.content.buttons.save.states.enabled = new DataContainer(false);
                      },
                    },
                    content: {
                      toolbar: {
                        title: "Cập nhật bảng phí",
                        subTitle(dialog) {
                          const btn = dialog.context();
                          const { item } = btn.context();
                          return item.name;
                        },
                      },
                      content: {
                        type: "XForm",
                        makeAttrs(attrs, dialog) {
                          const { item } = dialog.context().context();
                          attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                          attrs.xContext = dialog;
                          attrs.xData = new DataContainer({
                            nextFeePercentage: item.nextFeePercentage,
                            nextFeePercentageStartTime: item.nextFeePercentageStartTime,
                          });
                          attrs.xOptions = {
                            content: {
                              sections: {
                                default: {
                                  fields: {
                                    nextTariffItems: tariffItemsInput(),
                                    nextTariffItemsStartTime: {
                                      type: "XDateTimePicker",
                                      attrs: {
                                        label: "Ngày chuyển bảng phí",
                                        xOptions: {
                                          content: {
                                            resetable: true,
                                          },
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          };
                          return attrs;
                        },
                        attrs: {},
                      },
                      buttons: {
                        save: {
                          content: {
                            text: "Cập nhật",
                          },
                          states: {},
                          on: {
                            async xClick({ self }) {
                              const dialog = self.context();
                              const btn = dialog.context();
                              const { item, dataTable } = btn.context();
                              const form = dialog.contentContainer.value;
                              const data = form.getData();
                              self.loading.value = true;
                              const result = await dataTable.execute(
                                async () => {
                                  return await coreApiClient.call(
                                    "adsAgencies",
                                    "update",
                                    {
                                      id: item._id,
                                    },
                                    {
                                      data: {
                                        nextTariffItems: data.nextTariffItems,
                                        nextTariffItemsStartTime: data.nextTariffItemsStartTime,
                                      },
                                    }
                                  );
                                },
                                undefined,
                                {
                                  disableLoading: true,
                                  willRefresh: true,
                                }
                              );
                              self.loading.value = false;
                              if (result) {
                                dialog.hide();
                              }
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
              actionHistory: {
                content: {
                  icon: "mdi-format-list-bulleted-square",
                },
                target: {
                  tooltip: { content: { text: "Lịch sử tác động" } },
                  dialog: {
                    attrs: {
                      width: "80%",
                    },
                    content: {
                      autofocus: false,
                      toolbar: {
                        title: "Lịch sử tác động",
                        subTitle(self) {
                          const btn = self.context();
                          const { item } = btn.context();
                          return item.name;
                        },
                      },
                      contentIsNotDefaultComponent: true,
                      content: {
                        type: ItemHistory,
                        attrs: {
                          targetType: "AdsAgency",
                          displayFields: {
                            ...displayFields,
                            nextFeePercentage: {
                              text: "% phí mới",
                            },
                            nextFeePercentageStartTime: {
                              text: "Ngày chuyển % phí mới",
                              options: {
                                filter: "dateTime",
                              },
                            },
                            nextTariffItems: {
                              text: "Bảng phí mới",
                              options: {
                                labelItems(value) {
                                  return `min=${value.minimumAdsValue}; max=${value.maximumAdsValue}; %=${value.percentage}`;
                                },
                              },
                            },
                            nextTariffItemsStartTime: {
                              text: "Ngày chuyển bảng phí mới",
                              options: {
                                filter: "dateTime",
                              },
                            },
                            tariffItems: {
                              text: "Bảng phí",
                              options: {
                                labelItems(value) {
                                  return `min=${value.minimumAdsValue}; max=${value.maximumAdsValue}; %=${value.percentage}`;
                                },
                              },
                            },
                          },
                        },
                        makeAttrs(attrs: any = {}, dialog) {
                          const { item } = dialog.context().context();
                          attrs.item = item;
                          return attrs;
                        },
                      },
                      buttons: {
                        cancel: {
                          content: {
                            text: "Đóng",
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("adsAgencies", "findAll", options);
              return [items, count];
            },
            async insert(item) {
              return await coreApiClient.call("adsAgencies", "create", undefined, item);
            },
            async update(item) {
              return await coreApiClient.call(
                "adsAgencies",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("adsAgencies", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              name: {
                attrs: {
                  label: "Tên",
                  required: true,
                },
              },
              code: {
                attrs: {
                  label: "Mã",
                  required: true,
                },
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Mô tả",
                },
              },
              type: {
                type: "select",
                attrs: {
                  label: "Phân loại",
                  items: makeSelectOptions(adsAgencyTypes),
                },
                ext: {
                  defaultValue: "agency",
                },
              },
              adsCardId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Thẻ",
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("adsCards", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              contactPerson: {
                attrs: {
                  label: "Thông tin liên hệ",
                },
              },
              bankAccount: {
                attrs: {
                  label: "Tài khoản ngân hàng",
                },
              },
              feePercentage: {
                type: "number",
                attrs: {
                  label: "% phí",
                },
              },
              tariffItems: tariffItemsInput(),
            },
            insertForm: userManager.checkRole(["admin", "kt", "ksnb", "ksnbl"]) && {
              content: {
                fieldNames: [
                  "name",
                  "code",
                  "description",
                  "type",
                  "adsCardId",
                  "contactPerson",
                  "bankAccount",
                  "feePercentage",
                  "tariffItems",
                ],
              },
            },
            editForm: userManager.checkRole(["admin", "kt", "ksnb", "ksnbl"]) && {
              content: {
                fieldNames: [
                  "name",
                  "code",
                  "description",
                  "type",
                  "adsCardId",
                  "contactPerson",
                  "bankAccount",
                  "feePercentage",
                  "tariffItems",
                ],
              },
            },
            delete: userManager.checkRole(["admin"]) && {
              confirmDisplayField: "name",
            },
          },
        },
      },
    };
  },
});
